import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { API } from 'aws-amplify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'

// import blankPageStyle from "./BlankPageStyles";
import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import PermIdentity from '@material-ui/icons/PermIdentity'
import Button from '../../../components/CustomButtons/Button'

import Chip from '@material-ui/core/Chip'

import { FormattedMessage } from 'react-intl'

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx'

import DisplayHoldingAccount from '../../../components/HoldingAccount/DisplayHoldingAccount'
import HoldingAccountLineItemList from '../../../components/HoldingAccount/HoldingAccountLineItemList'
import queryString from 'query-string'
import CurrencyChip from "../../../components/CurrencyChip/CurrencyChip";

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}
//const min_transfer_amount = 0;

// let language = navigator.language.split(/[-_]/)[0];

class HoldingAccountPage extends React.Component {
    state = {
        account_list: [],
        active_currency: null,
        active_account: '',
        first: true,
        active_account_balance: 0,
        holding_account_list: [],
        refresh: false,

    }


    async componentDidMount() {
        await this.fetchHoldingAccounts();
        // const holding_account_list = await this.fetchHoldingAccounts()
        // const query_strings = queryString.parse(this.props.location.search)
        // console.log(query_strings)
        // console.log(holding_account_list)
        // if (query_strings.account) {
        //     this.createChips(holding_account_list)
        //     // this.setState(
        //     //     {
        //     //         first: false,
        //     //     },
        //     //     () => this.createChips(holding_account_list)
        //     // )
        // }
    }


    // componentDidUpdate() {
    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_client !== this.props.app_state.current_client) {
            await this.fetchHoldingAccounts();
        }

        if (prevProps.location.search !== this.props.location.search) {
            const query_strings = queryString.parse(this.props.location.search)
            // console.log(query_strings)
            await this.fetchHoldingAccounts();
        }
        
        // const holding_account_list = this.state.holding_account_list
        // const query_strings = queryString.parse(this.props.location.search)
        // if (
        //     query_strings.account &&
        //     this.state.account_list.length === 0 &&
        //     this.state.first
        // ) {
        //     this.setState({
        //         first: false,
        //     })
        //     this.createChips(holding_account_list)
        // }

        // if (!query_strings.account && !this.state.first) {
        //     this.setState({
        //         first: true,
        //     })
        // }
    }

    fetchHoldingAccounts = async () => {
        try {
            // const response = await API.get('holding_accounts', `/get/all`)
            if (!this.props.app_state.current_client) return [];
            const currentClient = this.props.app_state.current_client.id;
            const response = await API.get('holding_accounts', `/get/all/v2/${currentClient}`)
            // console.log(response)
            this.setState({
                holding_account_list: response.holding_account_list,
            })
            this.createChips(response.holding_account_list)
            // return response.holding_account_list
        } catch (error) {
            return []
        }
    }

    createChips = holding_account_list => {
        let chip_list = []

        var obj = {
            active_currency: null,
            active_account: '',
            active_account_balance: 0,
        }

        const query_strings = queryString.parse(this.props.location.search)
        // console.log('createChips')
        // console.log(query_strings)
        // console.log(holding_account_list)
        for (var idx in holding_account_list) {

            let record = holding_account_list[idx];

            chip_list.push({
                currencies_short_name: record.currencies_short_name,
                balance: record.balance,
                url: `/holding-account?account=${record.id}`,
                is_last_30_days: record.is_last_30_days
            })

            if (query_strings.account == record.id) {
                obj = {
                    active_account: (
                        <React.Fragment>
                            <div
                                style={{ marginLeft: 6, marginRight: 6 }}
                                className={`currency-flag currency-flag-${record.currencies_short_name.toLowerCase()}`}
                            />
                            {record.currencies_short_name.toUpperCase()}
                        </React.Fragment>
                    ),
                    active_currency: record.currency_id,
                    active_account_balance: new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: record.currencies_short_name,
                        currencyDisplay: 'narrowSymbol'
                    }).format(record.balance),
                }
            }
        }

        this.setState({
            account_list: chip_list,
            ...obj,
        })
    }
    

    refresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    render() {
        // const { classes } = this.props;
        const { classes } = this.props
        if (!this.props.app_state.current_client) {
            return null
        }

        const query_strings = queryString.parse(this.props.location.search)
        let main_component = null

        if (query_strings.account) {
            console.log("here", 123);
            main_component = (
                <HoldingAccountLineItemList
                    account={query_strings.account}
                    state={this.state}
                />
            )
        } else {
            main_component = (
                <DisplayHoldingAccount
                    prefill={this.props.match.params}
                    state={this.state}
                />
            )
        }

        // console.log(this.state.active_account, this.state.active_account_balance)
        return (
            <React.Fragment>
                <div>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <PermIdentity />
                            </CardIcon>
                            <div className={classes.flexEnd}>
                                <h4 className={classes.cardIconTitle}>
                                    {this.state.active_account && this.state.active_account_balance && 
                                    <strong>
                                        {this.state.active_account} Currency Balance:
                                        {' '}
                                        {this.state.active_account_balance}
                                    </strong>
                                    } 
                                    {(!this.state.active_account || !this.state.active_account_balance) && 
                                    <strong>
                                        Showing All Currency Balances
                                    </strong>
                                    }
                                </h4>
                                <div>
                                    {this.state.active_currency && (
                                    <Button
                                        round
                                        disabled={!this.state.active_currency}
                                        color="primary"
                                        onClick={() => {
                                            this.props.history.push(
                                                `/externalTransfer?toCurrency=${
                                                    this.state.active_currency
                                                }&toLedger=${
                                                    query_strings.account
                                                }`
                                            )
                                        }}
                                    >
                                        Add Funds
                                    </Button>
                                    )}
                                    {this.state.active_currency && (
                                    <Button
                                        round
                                        disabled={!this.state.active_currency}
                                        color="info"
                                        onClick={() => {
                                            this.props.history.push(
                                                `/externalTransfer?fromCurrency=${
                                                    this.state.active_currency
                                                }&fromLedger=${
                                                    query_strings.account
                                                }`
                                            )
                                        }}
                                    >
                                        Send Money
                                    </Button>
                                    )}
                                    {this.state.active_currency && (
                                    <Button
                                        round
                                        onClick={() => {
                                            this.setState({
                                                account_list: [],
                                                active_currency: null,
                                                active_account: '',
                                                active_account_balance: 0,
                                                firstTurn: false,
                                            })
                                            this.props.history.push(
                                                `/holding-account`
                                            )
                                        }}
                                    >
                                        Show All Balances
                                    </Button>
                                    )}
                                    {/* <Button
                                        round
                                        color="info"
                                        onClick={this.refresh.bind(this)}
                                    >
                                        <FormattedMessage
                                            id="newBeneficiary.cardItem1.refresh"
                                            defaultMessage={`Refresh`}
                                        />
                                    </Button> */}
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div style={{ marginBottom: '12px' }}>
                                <CurrencyChip borderColor={'#00acc1'}
                                              emitRecord={(record) => {
                                                  this.props.history.push(record.url);
                                              }}
                                              data={this.state.account_list} align={'end'} span={2}/>
                            </div>
                            <GridContainer>
                                <GridItem xs={12} sm={12}>
                                    {main_component}
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const HoldingAccountPageContainer = connect(
    mapStateToProps
    // ,mapDispatchToProps
)(withRouter(HoldingAccountPage))

export default withRouter(withStyles(styles)(HoldingAccountPageContainer))
