import React from 'react'
import { connect } from 'react-redux'
import { API } from 'aws-amplify'
// import {
//   fetchIdentificationList,
//   fetchIdentificationListAll
//   // setBeneficiarySelectId
// } from "../../redux/actions/identification";

// react component for creating dynamic tables
import ReactTable from 'react-table'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

import Dvr from '@material-ui/icons/Dvr'
import Button from 'components/CustomButtons/Button.jsx'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'
import { matchSorter } from 'match-sorter'

var moment = require('moment')

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
}

/**
 * ----------------------------------------------------------------------------
 * CLASS - IdentificationList
 * ----------------------------------------------------------------------------
 */
class HoldingAccountList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            holding_account_list: [],
        }
    }

    fetchHoldingAccounts = () => {
        API.get(
            'holding_accounts',
            `/get/all/v2/${this.props.app_state.current_client.id}`
        )
            .then(response => {
                this.setState({
                    holding_account_list: response.holding_account_list,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidUpdate(prevprops) {
        if (this.props.state.refresh != prevprops.state.refresh) {
            this.fetchHoldingAccounts()
        }
        if (prevprops.app_state !== this.props.app_state) {
            this.fetchHoldingAccounts()
        }
    }

    componentDidMount() {
        this.fetchHoldingAccounts()
    }

    // componentDidUpdate(prevProps) {
    //   if (prevProps.app_state !== this.props.app_state) {
    //     this.fetchHoldingAccounts()
    //   }
    // }

    buildTableData() {
        var holding_account_list_length = this.state.holding_account_list.length
        if (holding_account_list_length > 0) {
            // eslint-disable-next-line no-unused-vars
            return this.state.holding_account_list.map((prop, key) => {
                return {
                    id: prop.id,
                    client_id: prop.client_id,
                    client_nickname: prop.client_nickname,
                    currency_id: prop.currency_id,
                    // file_id: prop.file_id,
                    currencies_short_name: prop.currencies_short_name,
                    currencies_full_name: prop.currencies_full_name,
                    balance: prop.balance,
                    actions: (
                        <div className="actions-right">
                            <Button
                                // justIcon
                                // round
                                // simple
                                onClick={() => {
                                    this.props.history.push(
                                        `/holding-account?account=${prop.id}`
                                    )
                                }}
                                color="warning"
                                className="edit"
                            >
                                View
                            </Button>
                        </div>
                    ),
                }
            })
        }
    }

    render() {
        return (
            <ReactTable
                // data={this.state.entity_list}
                data={this.buildTableData()}
                filterable={false}
                columns={[
                    {
                        Header: 'Currencies ',
                        accessor: 'currencies_short_name',
                        /* filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["client_name"] }),
            filterAll: true,
            maxWidth: 150 */
                        maxWidth: 150,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                                keys: ['currencies_short_name'],
                            }),
                        filterAll: true,
                        Cell: c => (
                            <React.Fragment>
                                <div
                                    style={{ marginLeft: 6 }}
                                    className={`currency-flag currency-flag-${c.value.toLowerCase()}`}
                                />
                                &nbsp;
                                {`  `}
                                {c.value}
                            </React.Fragment>
                        ),

                        // filterMethod: (filter, row) => {
                        //   if (filter.value === "all") {
                        //     return true;
                        //   }
                        //   return row[filter.id] == filter.value;
                        // },
                        // Filter: ({ filter, onChange }) => (
                        //   <select
                        //     onChange={event => onChange(event.target.value)}
                        //     style={{ width: "100%" }}
                        //     value={filter ? filter.value : "all"}
                        //   >
                        //     <option value="all">Show All</option>
                        //     {client_name_optionlist}
                        //   </select>)
                    },
                    {
                        Header: '',
                        accessor: 'currencies_full_name',
                        sortable: false,
                        // filterMethod: (filter, rows) =>
                        //     matchSorter(rows, filter.value, {
                        //         keys: ['currencies_full_name'],
                        //     }),
                        // filterAll: true,
                        // maxWidth: 300
                    },
                    {
                        Header: 'Balance ',
                        accessor: 'balance',
                        /* filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["client_name"] }),
            filterAll: true,
            maxWidth: 150 */
                        maxWidth: 200,
                        // filterMethod: (filter, rows) =>
                        //   matchSorter(rows, filter.value, { keys: ["currencies_short_name"] }),
                        // filterAll: true,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    justifyContent: 'right',
                                    paddingRight: 24,
                                },
                            }
                        },
                        Cell: c => {
                            if (c.value) {
                                return (
                                    <React.Fragment>
                                        <div style={{textAlign: 'right'}}>
                                        {new Intl.NumberFormat('en-GB', {
                                            style: 'currency',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            currency: c.original.currencies_short_name,
                                            currencyDisplay: 'narrowSymbol'
                                        }).format(c.value)}
                                        </div>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment>
                                        <div style={{textAlign: 'right'}}>
                                        {new Intl.NumberFormat('en-GB', {
                                            style: 'currency',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            currency: c.original.currencies_short_name,
                                            currencyDisplay: 'narrowSymbol'
                                        }).format(0)}
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        },
                    },
                    {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                        maxWidth: 100,
                    },
                ]}
                defaultSorted={[
                    {
                        id: 'balance',
                        desc: true,
                    },
                ]}
                defaultPageSize={10}
                // showPaginationTop
                showPaginationBottom
                className="-highlight"
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        // identification: state.identification
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // fetchIdentificationList: (client_id) => {
        //   dispatch(fetchIdentificationList(client_id))
        // },
        // fetchIdentificationListAll: () => {
        //   dispatch(fetchIdentificationListAll())
        // }
        // setBeneficiarySelectId: (id) => {
        //   dispatch(setBeneficiarySelectId(id))
        // }
    }
}

const HoldingAccountListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HoldingAccountList)

export default withRouter(withStyles(styles)(HoldingAccountListContainer))
